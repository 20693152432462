import { Box } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"

import Textarea from "components/landing/textarea"
import Slider from "dependencies/carousel"

import ProductCarouselItem from "./product-carousel-item"

const ProductCarousel = ({ data, lazyload, linkClickData }) => {
  const {
    bottom_textarea,
    carousel_container_style,
    carousel_section_style,
    image_container_style,
    name,
    slides,
    slider_settings,
    top_textarea,
  } = data

  const sliderSettings = {
    arrows: false,
    autoplay: true,
    autoplayInterval: 4000,
    frameAriaLabel: name,
    wrapAround: true,
    ...slider_settings,
  }

  return (
    <Box {...carousel_section_style}>
      {top_textarea && (
        <Textarea
          element={top_textarea}
          linkClickData={linkClickData}
          style={top_textarea.style}
        />
      )}
      <Box {...carousel_container_style}>
        <Slider {...sliderSettings}>
          {slides.map((slide, index) => (
            <ProductCarouselItem
              imageContainerStyle={image_container_style}
              index={index}
              key={`${name}-${index}`}
              lazyload={lazyload}
              linkClickData={linkClickData}
              slide={slide}
            />
          ))}
        </Slider>
      </Box>
      {bottom_textarea && (
        <Textarea
          element={bottom_textarea}
          linkClickData={linkClickData}
          style={bottom_textarea.style}
        />
      )}
    </Box>
  )
}

ProductCarousel.propTypes = {
  data: PropTypes.object,
  lazyload: PropTypes.bool,
  linkClickData: PropTypes.string,
}

export default ProductCarousel
