import { Box } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"

import Textarea from "components/landing/textarea"

import LandingSlider from "./landing-slider"
import LooksSlider from "./slider/looks-slider"
import ProductCarousel from "./slider/product-carousel"

const LandingCarouselBlock = ({ element, lazyload }) => {
  if (!element || !element.data) return null

  const { arrow_style, name, slides, type, ...data } = element.data

  const arrowStyle = {
    ...(type === "featured"
      ? {
          arrowIcon: "arrow-white-transparent.svg",
          height: "60px",
          top: "50%",
          width: { desktop: "24px", tablet: "30px" },
        }
      : {
          arrowIcon: "arrow-dark-transparent.svg",
          nextPlacement: "-8px",
          prevPlacement: "-8px",
          top: "37%",
        }),
    ...arrow_style,
  }

  return (
    <Box {...element.container_style}>
      {type === "product" && (
        <ProductCarousel
          data={element.data}
          lazyload={lazyload}
          linkClickData={element.anchor_name || element.id}
        />
      )}
      {element.textarea && (
        <Box
          px={3}
          textAlign="center"
          {...element.style?.textarea_container_style}
        >
          <Textarea
            element={element.textarea}
            linkClickData={element?.anchor_name || element?.id}
          />
        </Box>
      )}
      {type === "featured" && (
        <LandingSlider
          element={{
            anchor_name: element?.anchor_name,
            arrow_style: arrowStyle,
            id: element?.id,
            items: slides,
            name,
            slider_type: type || "native",
            ...data,
          }}
          lazyload={lazyload}
        />
      )}
      {type === "looks" && (
        <LooksSlider
          element={{
            arrow_style: arrowStyle,
            name,
            ...element,
          }}
        />
      )}
    </Box>
  )
}

LandingCarouselBlock.propTypes = {
  element: PropTypes.object,
  lazyload: PropTypes.bool,
}

export default LandingCarouselBlock
