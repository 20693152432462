import { Box, Image } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

import { getPlaceholderData } from "areas/collection2/products/product-image"

const StyledLink = styled(Box).attrs({
  as: Link,
})`
  display: block;
  &:focus {
    outline-offset: -2px;
  }
`

const ProductCarouselItem = ({
  linkClickData,
  imageContainerStyle,
  index,
  lazyload,
  slide,
}) => {
  if (slide.type !== "product" || !slide.looks || Object.keys(slide.looks) == 0)
    return null

  const productLook = slide.default_look
    ? slide.looks[slide.default_look]
    : slide.looks[Object.keys(slide.looks)[0]]

  if (!productLook) return null

  const srcSet = productLook.images[0]
  const placeholder = getPlaceholderData(slide.product_type)

  return (
    <Box {...imageContainerStyle}>
      <StyledLink data-lc={linkClickData} to={productLook.product_url}>
        <Image
          alt={slide.name}
          fallback={require(`images/collections/placeholder_masks/${
            placeholder[0]
          }_${(index % placeholder[1]) + 1}.svg`)}
          lazyload={lazyload}
          ratio="auto 1 / 1"
          srcSet={srcSet}
        />
      </StyledLink>
    </Box>
  )
}

ProductCarouselItem.propTypes = {
  imageContainerStyle: PropTypes.object,
  index: PropTypes.number,
  lazyload: PropTypes.bool,
  linkClickData: PropTypes.string,
  slide: PropTypes.object,
}

export default ProductCarouselItem
