import { Box, Image, Typography } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import styled, { keyframes } from "styled-components"

import LooksSelector from "areas/collection2/looks-selector"
import { Badge } from "areas/collection2/products/collection-product"
import { getPlaceholderData } from "areas/collection2/products/product-image"
import { to_currency } from "helpers/number"

const ItemBox = styled(Box).attrs((props) => ({
  height: { tablet: "100%" },
  mx: { _: "6px", tablet: 0 },
  pb: 2,
  textAlign: "center",
  ...props,
}))``

const ImageContainer = styled(Box).attrs((props) => ({
  mx: "auto",
  position: "relative",
  textAlign: "center",
  ...props,
}))``

const Cta = styled(Box).attrs((props) => ({
  display: "inline-block",
  fontSize: "16px",
  fontWeight: "700",
  ...props,
}))`
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`

const ItemText = styled(Typography).attrs((props) => ({
  fontSize: { _: "16px", tablet: "18px" },
  fontWeight: "normal",
  mb: 2,
  ...props,
}))``

const SaleText = styled(Typography).attrs((props) => ({
  as: "span",
  color: "sale",
  ...props,
}))``

const shimmer = keyframes`
  from {
    background-position: -256px 0;
  }
  to {
    background-position: 256px 0;
  }
`

const StyledImage = styled(Image)`
  &.lazyloading {
    animation: ${shimmer} 1.5s forwards infinite;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    overflow: hidden;
  }
`

const SliderItem = ({ element, index, item }) => {
  const [selectedLook, setSelectedLook] = useState(item.default_look)

  const showLooks = element.data.show_metal_selector
  const selectedItem = selectedLook && item.looks[selectedLook]
  const badge = item.badges && item.badges[0]

  if (!selectedItem) return null

  const placeholder = getPlaceholderData(item.product_type)

  return (
    <ItemBox {...element.data.item_container_style}>
      <Link
        data-lc={element.anchor_name || element.id}
        to={selectedItem.product_url}
      >
        <ImageContainer {...element.data.image_container_style}>
          {badge && (
            <Badge
              bg={badge.color}
              color={badge.label_color}
              left="4px"
              top="4px"
            >
              {badge.label}
            </Badge>
          )}
          <StyledImage
            alt={item.name}
            fallback={require(`images/collections/placeholder_masks/${
              placeholder[0]
            }_${(index % placeholder[1]) + 1}.svg`)}
            ratio="1 / 1"
            sizes="(max-width: 767px)  40vw,
                   (max-width: 1023px) 33vw,
                   (max-width: 1599px) 25vw,
                   368px"
            srcSet={selectedItem.images[0]}
          />
        </ImageContainer>
      </Link>
      {showLooks && (
        <Box height="49px">
          {Object.keys(item.looks).length > 1 && (
            <Box py={1}>
              <LooksSelector
                border={element.container_style?.border}
                looks={item.looks}
                product={item}
                selectedLook={selectedLook}
                updateLook={setSelectedLook}
              />
            </Box>
          )}
        </Box>
      )}
      {item.name && (
        <ItemText {...element.data.item_name_style} {...item.titleStyle}>
          {item.name}
        </ItemText>
      )}
      {element.data.show_prices && (
        <ItemText {...element.data.item_price_style}>
          <SaleText color="sale">SAVE {selectedItem.sale_percent}%</SaleText>
          <br />
          from {to_currency(selectedItem.selling)}{" "}
          <span style={{ fontWeight: "400", textDecoration: "line-through" }}>
            ({to_currency(selectedItem.retail)})
          </span>
        </ItemText>
      )}
      <Cta
        as={Link}
        data-lc={element.anchor_name || element.id}
        to={selectedItem.product_url}
      >
        Shop Now
      </Cta>
    </ItemBox>
  )
}

SliderItem.propTypes = {
  element: PropTypes.object,
  index: PropTypes.number,
  item: PropTypes.object,
}

export default SliderItem
