import { Box, Carousel, MediaBox } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import { StyledArrowImage } from "areas/landing/elements/components"
import Slider from "dependencies/carousel"
import useWindowDimensions from "helpers/window-dimensions"

import SliderItem from "./looks-slider-item"

const SliderContainer = styled(Box).attrs((props) => ({
  containWide: true,
  pl: 2,
  pr: { tablet: 2 },
  ...props,
}))``

const StyledSlider = styled(Slider)`
  .slider-control-bottomcenter {
    margin-bottom: -44px;
  }
`

const SliderWrapper = ({ children, ...props }) => {
  return (
    <>
      <MediaBox lessThan="tablet">
        <Carousel displayArrows="none">{children}</Carousel>
      </MediaBox>
      <MediaBox greaterThanOrEqual="tablet">
        <StyledSlider {...props}>{children}</StyledSlider>
      </MediaBox>
    </>
  )
}

SliderWrapper.propTypes = {
  children: PropTypes.node,
  sliderType: PropTypes.string,
}

const LooksSlider = ({ element }) => {
  const { isMobile, isTablet } = useWindowDimensions()

  const { top: arrowTop, nextPlacement, prevPlacement, ...arrowStyle } =
    element.arrow_style || {}

  const sliderSettings = {
    arrowTop: arrowTop || "38%",
    cellSpacing: "24",
    defaultControlsConfig: {
      nextButtonText: (
        <StyledArrowImage
          {...arrowStyle}
          alt="Next"
          height="30px"
          src={
            arrowStyle?.arrowIcon
              ? require(`images/icons/${arrowStyle.arrowIcon}`)
              : require("images/icons/arrow_bg_light.svg")
          }
          width="30px"
        />
      ),
      prevButtonText: (
        <StyledArrowImage
          {...arrowStyle}
          alt="Previous"
          height="30px"
          src={
            arrowStyle?.arrowIcon
              ? require(`images/icons/${arrowStyle.arrowIcon}`)
              : require("images/icons/arrow_bg_light.svg")
          }
          width="30px"
        />
      ),
    },
    dragThreshold: 0.4,
    frameAriaLabel: element?.name || element.sliderSettings?.frameAriaLabel,
    nextPlacement,
    prevPlacement,
    slidesToScroll: isMobile ? 2 : isTablet ? 3 : 4,
    slidesToShow: isMobile ? 2 : isTablet ? 3 : 4,
    speed: 300,
    ...element.data?.slider_settings,
  }

  return (
    <SliderContainer {...element.style}>
      <SliderWrapper {...sliderSettings} {...element.slider_style}>
        {element.data.products.map((item, index) => (
          <SliderItem
            element={element}
            index={index}
            item={item}
            key={`slider-${element.id}-${index}`}
          />
        ))}
      </SliderWrapper>
    </SliderContainer>
  )
}

LooksSlider.propTypes = {
  element: PropTypes.object,
}

export default LooksSlider
